<template>
  <b-card class="mt-2">
    <div>
      <h4 class="mb-2"> {{ $t('Expert Video Nuggets') }}</h4>
      <b-row v-if="VIDEO_NUGGETS" class="">
        <b-col md="6" xl="3" sm="6" v-for="videonugget in VIDEO_NUGGETS"
               :key="videonugget.id"
               class="mb-2"
        >

          <VideoNuggetsCard :videonugget="videonugget"/>
        </b-col>
      </b-row>
      <b-row v-if="VIDEO_NUGGETS && VIDEO_NUGGETS.length == 0">
        <b-col sm="6" md="6" xl="3" class="mb-2">
          <div class="image_header">
            <img src="@/assets/images/svg/email.svg"/>
          </div>
          <b-card>
            <b-card-body class="expert-events-card-body ">
              <h3 class="h3 card-txt text-center py-3 px-2">
                 {{ $t('Create your first Video Nugget by clicking the Button below') }}
              </h3>
            </b-card-body>
            <b-card-footer class="justify-content-center d-flex">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  type="submit"
                  size="sm"
                  :to="{ name: 'apps-videosnippets-new' }"
              >
                {{ $t('create your first Video Nugget') }}
              </b-button>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardTitle,
  BCol,
  BEmbed,
  BFormRating,
  BRow,
} from 'bootstrap-vue'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import VideoNuggetsCard from '@/views/profile/profile-parts/VideoNuggetsCard'

export default {

  components: {
    VideoNuggetsCard,
    BCard,
    BBadge,
    BAvatar,
    BButton,
    BFormRating,
    BRow,
    BCol,
    BCardBody,
    BCardFooter,
    BCardTitle,
    BEmbed,
  },
  filters: {
    moment: function (date) {
      return this.formateDateIfGerman(date)
    }
  },

  directives: {
    Ripple,
  },

  methods: {
    ...mapActions(['GET_VIDEO_NUGGETS']),

  }

  ,
  mounted() {

    this.GET_VIDEO_NUGGETS()

  },

  computed: {
    ...mapGetters(['VIDEO_NUGGETS'])

  },

}
</script>
<style lang="scss">
.card-profile {
  padding-top: 7rem;
}

.profile-badge {
  + .profile-badge {
    margin-left: 0.5rem;
  }
}

.video-nuggets-card {
  text-align: center;
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  min-height: 220px;
}

.video-nuggets {
  max-height: 200px;
  min-height: 200px;
}

.slogan-videonuggets {
  min-height: 120px;
}

.video-nuggets-description {
  width: 100%;

  p {
    text-align: center;
    width: 100%;
  }
}

.image_header {
  background-color: #F1F0FE;
}


[dir] .card .card {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
}


</style>
