<template>
  <div>

    <h3 class="mt-2">
      {{ currentItem.firstname }} {{ currentItem.name }}
    </h3>
    <h6 class="mt-2" v-if="!currentItem.institute && !currentItem.company && !currentItem.city && !currentItem.position && !currentItem.degree">
       {{$t('Please add your Infos')}}
    </h6>

    <b-row v-if="isEditable==true">
      <b-col class="mb-1 mt-2">
        <b-form-group :label="$t('First Name')">
          <b-form-input
              v-model="currentItem.firstname"
              id="firstname"
              :placeholder="$t('First Name')"
          />
        </b-form-group>
      </b-col>
      <b-col class="mb-1 mt-2">
        <b-form-group :label="$t('Name')">
          <b-form-input
              v-model="currentItem.name"
              id="name"
              :placeholder="$t('Name')"
          />
        </b-form-group>
      </b-col>
    </b-row>


    <h6 class="text-muted mt-2">
      {{ currentItem.position }}  <span v-if="currentItem.position && currentItem.degree ">-</span> {{ currentItem.degree }}
    </h6>
    <h6 class="text-muted mt-2">
      {{ currentItem.company }} <span v-if="currentItem.company && currentItem.institute ">-</span> {{ currentItem.institute }}
    </h6>
    <h6 class="mt-2">
      {{ currentItem.city }}
    </h6>
    <router-link to="/events/list">
      <b-badge
          pill
          variant="light-primary"
          class="mt-1 mb-1 "

      >
        {{ $t('See available Events') }}
      </b-badge>
    </router-link>
    <hr class="ml-4 mr-4" style="height:1px;border:none;color:#a8a6a6;background-color:#b1b0b0;"/>
    <p>{{ currentItem.slogan }}</p>
  </div>
</template>

<script>
import { BBadge, BCol, BFormGroup, BFormInput, BRow, VBModal, VBToggle, } from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  name: 'ProfileHeaderInfo',
  components: {
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BBadge,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  props: ['currentItem', 'isEditable'],
}
</script>
