<template>
  <b-row>
    <b-col cols="12">
      <b-alert
          variant="warning"
          show>
        <h4 class="alert-heading">
          {{ $t('Are you sure you want to delete your account?') }}
        </h4>
        <div class="alert-body">


            {{ $t('Once you delete your account, there is no going back. Please be certain.') }}
        </div>
      </b-alert>
    </b-col>
    <b-col cols="12">
      <b-form-group>
        <b-form-checkbox
            id="checkbox-9"
            name="checkbox-9"
            value="Remember_me"
            v-model="confirmDelete">
            {{ $t('I confirm my account deactivation') }}
        </b-form-checkbox>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          :disabled="!confirmDelete"
          @click="deleteAccount()"
      >
          {{ $t('Delete my Account') }}
      </b-button>
    </b-col>
  </b-row>
</template>
<script>

import {
  BAlert,
  BButton,
  BCol,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BLink,
   BRow,

} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  name: "Account",
  components: {
    BFormGroup,
    BRow,
    BAlert,
    BInputGroup,
    BButton,
    BFormInput,
    BCol,
    BFormCheckboxGroup,
    BFormCheckbox,
    BLink,
  },
  data() {
    return {
      confirmDelete: false
    }
  },
  directives: {
    Ripple,
  },
  methods: {
    deleteAccount() {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it!'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`/expert/delete`)
              .then(response => {
                localStorage.clear()
                this.$router.push({name: 'auth-login'})
              })
              .catch(error => {
                console.debug(error)
                this.$bvToast.toast(this.$i18n.t('User not deleted'), {
                  title: this.$i18n.t('Error'),
                  variant: 'danger',
                  toaster: 'b-toaster-bottom-right'
                })
              })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
