<template>
  <b-row>
    <b-col cols="6">
      <b-form-group :label="$t('Password')">
        <validation-provider
            #default="{ errors }"
            :label="$t('Password')"
            :name="$t('Password')"
            vid="Password"
            rules="min:8|password"
            class="mt-3">
          <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null">
            <b-form-input
                v-model="currentItem.password"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                :type="passwordFieldType"
                :placeholder="$t('Password')"/>
            <b-input-group-append is-text>
              <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">{{ $t(errors[0]) }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <!-- confirm password -->
    <b-col cols="6">
      <b-form-group :label="$t('Confirm Password')">
        <validation-provider
            #default="{ errors }"
            :name="$t('Confirm Password')"
            rules="confirmed:Password"
        >
          <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
          >
            <b-form-input
                v-model="currentItem.password_confirmation"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                :type="passwordconfirmFieldType"
                :placeholder="$t('Confirm Password')"
            />
            <b-input-group-append is-text>
              <feather-icon
                  class="cursor-pointer"
                  :icon="passwordconfirmToggleIcon"
                  @click="togglePasswordConfirmVisibility"
              />
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">{{ $t(errors[0]) }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  VBModal,
  VBToggle,
} from 'bootstrap-vue'
import { ValidationProvider} from "vee-validate";
import Ripple from "vue-ripple-directive";
import {togglePasswordVisibility} from "@core/mixins/ui/forms";
import {heightTransition} from "@core/mixins/ui/transition";

export default {
  name: "LoginDetails",
  components: {
    ValidationProvider,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BRow,

  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  props: ['currentItem'],
  mixins: [togglePasswordVisibility, heightTransition],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordconfirmToggleIcon() {
      return this.passwordconfirmFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },methods:{
    getValidationState({
                         dirty,
                         validated,
                         valid = null
                       }) {
      return dirty || validated ? valid : null
    },
  }
}
</script>

