<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card
        class="card-profile  pt-0"
        :img-src="require('@/assets/images/banner/parallax.jpg')"
        img-top
        alt="cover photo"
        body-class="p-0"
    >
      <div class="position-relative">
        <div class="profile-img-container ">
          <div class="profile-image p-0  w-100 d-flex flex-column align-items-center ">
            <b-avatar
                style="margin-top: -65px;"
                class="profile-avatar"
                ref="previewEl"
                alt="Avatar"
                :src="currentItem.avatar.url"
                size="114px"
                variant="light"
            />
            <div class="text-body align-middle  cursor-pointer  align-self-end mr-5 position-relative" v-if="!isEditable">
              <b-button
                  data-action="collapse"
                  class="btn btn-icon btn-secondary"
                  @click="isEditable = !isEditable"
              >
              <feather-icon
                  v-if="!isEditable"
                  icon="EditIcon"
                  size="16"
                  no body/>
              </b-button>
            </div>
          </div>
        </div>
        <!--        profile header end-->
        <div class="profile-box profile-title" v-if="!isEditable">
          <ProfileHeaderInfo :isEditable="isEditable" :currentItem="currentItem"/>
          <time-lines :currentItem="currentItem"/>
        </div>
        <!--        profile info end-->
        <div v-else class="edit-mode p-3">
          <div class="position-relative d-flex align-items-center">
            <h4 class="mb-0">
              {{ $t('Edit your Profile') }}
            </h4>
            <div class="ml-auto align-self-center">
              <b-button
                  class="btn-icon"
                  data-action="save"
                  @click="updateItem()"
                  variant="success"
                  :disabled="!loaded"
              >
                <feather-icon
                    v-if="loaded"
                    icon="SaveIcon"
                    size="14"
                />
                <b-spinner v-else small />
              </b-button>
            </div>
          </div>
          <b-form
              @submit.stop.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
          >
            <hr/>
            <b-tabs vertical
                    content-class="col-12 col-md-9 mt-1 mt-md-0"
                    pills
                    nav-wrapper-class="col-md-3 col-12"
                    nav-class="nav-left" v-model="tabIndex" ref="tabContainer">
              <b-tab :title="$t('Public Profile')" title-link-class="justify-content-start">
                <template #title>
                  <feather-icon icon="UserIcon"/>
                  {{ $t('Public Profile') }}
                </template>
                <public-profile :currentItem="currentItem" @updateProfile="getProfile"/>
              </b-tab>
              <b-tab :title="$t('Personal Data')" title-link-class="justify-content-start">
                <template #title>
                  <feather-icon
                      icon="DatabaseIcon"
                  />
                  {{ $t('Personal Data') }}
                </template>
                <personal-data :currentItem="currentItem"/>
              </b-tab>
              <b-tab :title="$t('Login Details')" title-link-class="justify-content-start">
                <template #title>
                  <feather-icon
                      icon="ToolIcon"
                  />
                  {{ $t('Login Details') }}
                </template>
                <login-details :currentItem="currentItem"/>

              </b-tab>
              <b-tab :title="$t('Experiences')" title-link-class="justify-content-start">
                <template #title>
                  <feather-icon
                      icon="CompassIcon"
                  />
                  {{ $t('Experiences') }}
                </template>
                <Experiences :currentItem="currentItem"/>
              </b-tab>
              <b-tab :title="$t('Sections')" title-link-class="justify-content-start">
                <template #title>
                  <feather-icon
                      icon="FramerIcon"
                  />
                  {{ $t('Sections') }}
                </template>
                <sections :currentItem="currentItem"/>
              </b-tab>
              <b-tab :title="$t('User Account')" title-link-class="justify-content-start">
                <template #title>
                  <feather-icon
                      icon="UserIcon"/>
                  {{ $t('Account') }}
                </template>
                <account/>
              </b-tab>
            </b-tabs>
            <b-row>
              <b-col cols="12 mt-2">
                <b-button
                    variant="success"
                    type="submit"
                    @click.prevent="updateItem"
                    class="save-button border-left"
                    :disabled="!loaded"
                >
                  <b-spinner v-if="!loaded" small />
                  {{ $t('Save Changes') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
        <!--        profile tabs end-->
      </div>
    </b-card>

    <div v-if="!isEditable">
<!--      <reviews/>-->
<!--      <event-impressions/>-->
<!--      <videonuggets/>-->
    </div>
  </component>
</template>
<script>
import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardText,
  BCol,
  BCollapse,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BMedia,
  BModal,
  BProgress,
  BRow,
  BSidebar,
  BTab,
  BTabs,
  BToast,
  BSpinner,
  VBModal,
  VBToggle,
} from 'bootstrap-vue'

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {FormWizard, TabContent} from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {heightTransition} from '@core/mixins/ui/transition'
import Reviews from "@/views/profile/profile-parts/Reviews";
import Videonuggets from "@/views/profile/profile-parts/Videonuggets";
import EventImpressions from "@/views/profile/profile-parts/EventImpressions";
import Account from "@/views/profile/profile-tabs/Account";
import TimeLines from "@/views/profile/profile-tabs/TimeLines";
import Sections from "@/views/profile/profile-tabs/Sections";
import Experiences from "@/views/profile/profile-tabs/Experiences";
import ProfileHeaderInfo from "@/views/profile/profile-parts/ProfileHeaderInfo";
import LoginDetails from "@/views/profile/profile-tabs/LoginDetails";
import PersonalData from "@/views/profile/profile-tabs/PersonalData";
import PublicProfile from "@/views/profile/profile-tabs/PublicProfile";
import {mapActions} from "vuex";
export default {
  components: {
    PublicProfile,
    PersonalData,
    LoginDetails,
    ProfileHeaderInfo,
    Experiences,
    Sections,
    TimeLines,
    Account,
    EventImpressions,
    Videonuggets,
    Reviews,
    BSidebar,
    BSpinner,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    vSelect,
    BFormFile,
    BAvatar,
    BMedia,
    BInputGroupAppend,
    BInputGroup,
    Cleave,
    BImg,
    BBadge,
    BCollapse,
    VBToggle,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    AppCollapse,
    AppCollapseItem,
    BProgress,
    BDropdown,
    BDropdownItem,
    BModal,
    VBModal,
    FormWizard,
    TabContent,
    ToastificationContent,
    BTabs,
    BTab,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  mixins: [togglePasswordVisibility, heightTransition],
  props: ['activeTab'],
  data() {
    return {
      isSaving: false,
      tabIndex: 0,
      firstname: '',
      currentItem: {
        avatar: {
          url: '',
        },
        firstname: '',
        name: '',
        position: '',
        degree: '',
        company: '',
        institute: '',
        city: '',
        aboutme: '',
        slogan: '',
        birthday: '',
        email: '',
        phone: '',
        password_confirmation: '',
        password: '',
        id: '',
        sections: [],
        targetgroups: [],
        languages: [],
        expertTopics: [],
        experiences: [{
          id: 1,
          selected: 'male',
          selected1: 'designer',
          prevHeight: 0,
        }],
      },
      isEditable: false,
      loaded: false,
      nextTodoId: 2,
    }
  },


  methods: {
    getProfile(){
      this.helperGetItem(`/experts/profile`)
    },
    updateItem() {
      this.helperUpdateItem(`/experts/profile`, this.currentItem, function(data, context) {
          context.$store.dispatch('GET_USER_ONBOARDING')
      })
    },
    onSubmit() {
      this.updateItem()
    },
    getValidationState({
                         dirty,
                         validated,
                         valid = null
                       }) {
      return dirty || validated ? valid : null
    },
    scrollToTabContainer() {
      if (this.$refs.tabContainer !== undefined) {
        const rootEle = document.documentElement
        rootEle.scrollTo({
          top:  this.$refs.tabContainer.$el.getBoundingClientRect().top,
          behavior: 'smooth',
        })
      }
    }
  },
  mounted() {
    this.getProfile()
  },
  watch: {
    '$route.params.activeTab': {
      handler: function(activeTab) {
        this.tabIndex = parseInt(activeTab)
        window.setTimeout(this.scrollToTabContainer, 200)
        this.isEditable = true
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style>
.profile-avatar {
  border-color: pink;
  border-radius: 50%;
  box-shadow: 0 0 0 5px #ffffff;
}

.profile-img-container {
  justify-content: center;
}

.dropdown-setting {
  align-items: end !important;
  margin-left: 930px;
}

.timeline-experience {
  margin-left: 400px;
  margin-right: 400px;
  text-align: left !important;
  /*display: flex !important;*/
}

.topics-experience {
  margin-left: 400px;
  margin-right: 400px;
  /*display: flex !important;*/
}

.experience-years {
  margin-left: 400px;
  position: absolute;
}

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}

.edit-mode {
  text-align: left !important;
}

.save-button {
  text-align: right !important;
  align-items: flex-end !important;
}

.expert-topics-badge {
  display: grid !important;
}


.custom-line-topics {
  max-width: 470px;
  border-bottom: 1px solid rgb(185, 185, 195);
}

.edit_ico{
  bottom: -43px;
}

</style>
