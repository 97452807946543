<template>
  <div class="mb-4">
    <div>

      <b-form
          ref="form"
          class="repeater-form"
          @submit.prevent="repeateAgain"
      >
        <draggable
            v-model="currentItem.experiences"
            class="list-group list-group-flush cursor-move"
            tag="ul"
        >
          <transition-group
              type="transition"
              name="flip-list"
          >
            <b-list-group-item
                v-for="(experiences, index) in currentItem.experiences"
                :key="experiences.id"
                tag="div"
                class="d-flex row"
            >
              <!-- Item Name -->
              <b-col md="2">
                <b-form-group
                    :label="$t('Job Title')"
                >
                  <b-form-input
                      type="text"
                      v-model="experiences.job_title"
                      :placeholder="$t('Job Title')"
                  />
                </b-form-group>
              </b-col>

              <!-- Cost -->
              <b-col md="2">
                <b-form-group
                    :label="$t('Company Name')"
                >
                  <b-form-input
                      type="text"
                      v-model="experiences.company_name"
                      :placeholder="$t('Name of the Company')"
                  />
                </b-form-group>
              </b-col>

              <!-- Quantity -->
              <b-col md="2">
                <b-form-group
                    :label="$t('Quick Description')"
                >
                  <b-form-input
                      type="text"
                      v-model="experiences.description"
                      :placeholder="$t('Quick Description')"
                  />
                </b-form-group>
              </b-col>

              <!-- Profession -->
              <div class="col-12">
                <div class="row">
                  <b-col md="12">
                    <b-form-group>
                      <div class="custom-control custom-checkbox">

                        <input type="checkbox" class="custom-control-input" v-model="experiences.present"
                               :id="experiences.id+experiences.id">
                        <label class="custom-control-label" :for="experiences.id+experiences.id"></label>
                        {{ $t('Currently working in this position') }}
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                        :label="$t('Start month')">
                      <b-form-select v-model="experiences.start_month"
                                     :options="months"
                                     value-field="number"
                                     text-field="name"
                                     :placeholder="$t('Month')"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                        :label="$t('Start year')">
                      <b-form-select v-model="experiences.start_year"
                                     :options="years"
                                     text-field="value"
                                     :placeholder="$t('Year')"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="2" v-if="!experiences.present">
                    <b-form-group
                        :label="$t('End month')">
                      <b-form-select v-model="experiences.end_month"
                                     :options="months"
                                     value-field="number"
                                     text-field="name"
                                     :placeholder="$t('Month')"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="2" v-if="!experiences.present">
                    <b-form-group
                        :label="$t('End year')">
                      <b-form-select v-model="experiences.end_year"
                                     :options="years"
                                     text-field="value"
                                     :placeholder="$t('Year')"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </div>

              </div>


              <b-col
                  cols="12"

              >
                <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mb-sm-1 mb-md-0"
                    @click="removeItem(index)"
                >
                  <feather-icon
                      icon="XIcon"
                      class="mr-25"
                  />
                  <span>  {{ $t('Delete') }}</span>
                </b-button>
              </b-col>
            </b-list-group-item>
          </transition-group>
        </draggable>
      </b-form>


    </div>
    <b-button
        class="mt-2"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-success"
        @click="repeateAgain"
    >
      <feather-icon
          icon="PlusIcon"
          class="mr-25"
      />
      <span> {{ $t('Add more') }}</span>
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BListGroupItem,
  BRow,
  BFormSelect,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import draggable from 'vuedraggable'
import moment from 'moment'

export default {
  name: "Experiences",
  components: {
    draggable,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BCol,
    BListGroupItem,
    BRow

  }, directives: {
    Ripple,
  },
  data() {
    return {
      nextTodoId: 2,
      months: [
        {
          "name": this.$i18n.t("January"),
          "number": 1,
        },
        {
          "name": this.$i18n.t("February"),
          "number": 2,
        },
        {
          "name": this.$i18n.t("March"),
          "number": 3,
        },
        {
          "name": this.$i18n.t("April"),
          "number": 4,
        },
        {
          "name": this.$i18n.t("May"),
          "number": 5,
        },
        {
          "name": this.$i18n.t("June"),
          "number": 6,
        },
        {
          "name": this.$i18n.t("July"),
          "number": 7,
        },
        {
          "name": this.$i18n.t("August"),
          "number": 8,
        },
        {
          "name": this.$i18n.t("September"),
          "number": 9,
        },
        {
          "name": this.$i18n.t("October"),
          "number": 10,
        },
        {
          "name": this.$i18n.t("November"),
          "number": 11,
        },
        {
          "name": this.$i18n.t("December"),
          "number": 12,
        }
      ],


    }
  },
  computed: {
    sortedItems() {
      return this.currentItem.experiences


    },


    years() {
      const yearsArray = [];
      const startDate = moment().format('YYYY') - 100;
      for (let i = startDate; i <= startDate + 100; i++) {
        yearsArray.push({value: i})
      }

      return yearsArray.reverse();
    }

  },

  props: ['currentItem'],
  methods: {
    repeateAgain() {
      this.currentItem.experiences.push({
        id: this.nextTodoId += this.nextTodoId,
        start_year: 2022,
        end_year: 2022,
        "start_month": 1,
        "end_month": 1,
        "present": 0


      })

    },
    removeItem(index) {
      this.currentItem.experiences.splice(index, 1)

    },
    getValidationState({
                         dirty,
                         validated,
                         valid = null
                       }) {
      return dirty || validated ? valid : null
    },
  }
}
</script>

<style scoped>

</style>
