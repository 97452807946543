<template>
  <div class="timeline-container mt-4">
    <div class="mb-2">
            <span
                v-show="!visible"
                class="cursor-pointer text-muted"
                @click="$emit('close-toast')"
                v-b-toggle.collapse-1
            > {{ $t('Show more') }}
              <feather-icon
                  icon="ChevronDownIcon"
                  class="text-body text-muted"
              />
            </span>
    </div>
    <div class="mb-2">
      <b-collapse id="collapse-1" class="timeline-experience">
        <app-timeline v-if="currentItem.experiences">
          <div v-for="expiriece in  sortedItems" :key="expiriece.id">
            <app-timeline-item>
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h5>{{ expiriece.job_title }}</h5>
                <small class="text-muted"> {{ experienceTransform(expiriece) }} ,
                  {{ calculatePeriod(expiriece) }}</small>


              </div>
              <div class="flex-wrap mb-1 mb-sm-0">
                <b-row>
                  <p class="align-items-left ml-2">{{ expiriece.company_name }}</p>
                </b-row>
                <b-row>
                  <p>
                    <span class="text-muted ml-3">{{ expiriece.description }}</span>
                  </p>
                </b-row>
              </div>
            </app-timeline-item>
          </div>
        </app-timeline>
      </b-collapse>
    </div>
    <!--      EXPIRIENCE LIST end-->
    <div class="mt-4 mb-4">
      <b-collapse id="collapse-1" class="topics-experience">
        <app-timeline>
          <app-timeline-item variant="primary" v-if="currentItem.experttopics">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h5> {{ $t('Experttopics') }}</h5>
            </div>
            <b-row
                v-for="option in currentItem.experttopics"
                :key="option.id"
                :value="option.id"
            >
              <div class="col-12 mt-1 mb-1 d-flex justify-content-between align-items-center">
                <b-badge
                    pill
                    variant="light-primary"
                >
                  {{ option.name }}
                </b-badge>
                <div class="years_side d-flex align-items-center justify-content-end flex-grow-1">
                  <span class="line text-muted custom-line-topics mr-2 flex-grow-1 "></span>
                  <small class="text-muted">{{
                      periodTransform(option.pivot.experience_years, option.pivot.period)
                    }} </small>


                </div>
              </div>
            </b-row>
          </app-timeline-item>
          <app-timeline-item variant="primary">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h5>{{ $t('Sections') }}</h5>
            </div>
            <b-row>
              <b-badge
                  pill
                  v-for="section in currentItem.sections"
                  :key="section.id"
                  v-model="currentItem.sections"
                  :value="section.id"
                  name="sections"
                  variant="light-primary"
                  class=" ml-1 mt-1"
              >
                {{ section.name }}
              </b-badge>
            </b-row>
          </app-timeline-item>
          <app-timeline-item variant="primary">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h5> {{ $t('Target Groups') }}</h5>
            </div>
            <b-row>
              <b-form-group>
                <b-badge
                    pill
                    v-for="targetgroup in currentItem.targetgroupsCurrentList"
                    :key="targetgroup.id"

                    name="targetgroups"
                    variant="light-primary"
                    class="align-items-left ml-1 mt-1"
                >
                  {{ targetgroup.name }}
                </b-badge>
              </b-form-group>

            </b-row>
          </app-timeline-item>

          <app-timeline-item variant="primary">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h5> {{ $t('Languages') }}</h5>
            </div>
            <b-row>
              <b-badge
                  v-for="language in currentItem.languagesCurrentList"
                  :key="language.key"
                  pill
                  variant="light-primary"
                  class="align-items-left ml-1 mt-1"
              >
                {{ language.name }}
              </b-badge>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <div class="mb-2 mt-2" id="content">
          <span
              class="cursor-pointer text-muted"
              @click="$emit('close-toast')"
              v-b-toggle.collapse-1

          > {{ $t('Show less') }}
          <feather-icon
              icon="ChevronUpIcon"
              class="text-body text-muted"
          />
          </span>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import {
  BBadge,
  BCollapse,
  BFormGroup,
  BRow,
  VBModal,
  VBToggle,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from "vue-ripple-directive";
import moment from 'moment'

export default {
  name: "TimeLines",
  components: {
    AppTimelineItem,
    BRow,
    BFormGroup,
    BBadge,
    AppTimeline,
    BCollapse,
  },
  data() {
    return {
      visible: false,
    }
  },
  props: ['currentItem'],
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  computed: {

    lang() {
      return this.$i18n.locale
    },

    sortedItems() {
      if (this.currentItem.experiences.length) {
        const sortable = this.currentItem.experiences.sort((a, b) => a.start_year - b.start_year).sort((a, b) => a.present - b.present);
        return sortable.reverse()
      } else {
        return []
      }
    },
  },


  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      this.visible = isJustShown
    })
  },


  methods: {
    experienceTransform(experience) {
      let start_date = moment(experience.start_month, 'M').lang(this.lang).format('MMMM') + ' ' + experience.start_year;
      let end_date = moment(experience.end_month, 'M').lang(this.lang).format('MMMM') + ' ' + experience.end_year;
      if (!experience.present) {
        return start_date + ' - ' + end_date
      } else {
        return start_date + ' - ' + this.$i18n.t('Present')
      }
    },
    calculatePeriod(experience) {
      let start_date = moment(experience.start_year + '-' + experience.start_month + '-1')
      let end_date = moment(experience.end_year + '-' + experience.end_month + '-1')

      if (experience.present) {
        end_date = moment()

      }

      let diffYears = end_date.diff(start_date, 'year');
      start_date.add(diffYears, 'years');

      let diffMonths = end_date.diff(start_date, 'months');
      start_date.add(diffMonths, 'months');

      let month = diffMonths ? diffMonths : ''
      let year = diffYears ? diffYears : ''

      let wordYear = year ? this.$i18n.t('Year') : '';
      let wordMont = month ? this.$i18n.t('Month') : '';
      if (year > 1) {
        wordYear = this.$i18n.t('Years')
      }
      if (month > 1) {
        wordMont = this.$i18n.t('Months')
      }

      return year + ' ' + wordYear + '  ' + month + ' ' + wordMont

    },



    periodTransform(year, period) {

      if (period === 'month') {
        if (year > 1) {
          return year + " " + "Months"
        } else {
          return year + " " + "Month"
        }
      }

      if (period === 'year') {
        if (year > 1) {
          return year + " " + "Years"
        } else {
          return year + " " + "Year"
        }
      }
    }
  }

}
</script>

