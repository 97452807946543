<template>
  <div>
    <b-media class="mb-1 mt-2 ml-2">
      <template #aside>
        <b-avatar
            ref="previewEl"
            :src="currentItem.avatar.url"
            size="90px"
            rounded
        />
      </template>

      <div class="d-flex flex-wrap position-relative">
        <b-button
            class="ml-1 mt-4"
            variant="success"
            @click="$refs.refInputEl.click()"
        >
          <input
              ref="refInputEl"
              type="file"
              class="d-none"
              @input="addAvatar"
          />
          <span v-if="currentItem.avatar.url">
                {{ $t('Update') }}
              </span>
          <span v-else>
                {{ $t('Add') }}
              </span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none"/>
        </b-button>
        <b-button
            v-if="currentItem.avatar.url"
            variant="danger"
            class="ml-1 mt-4"
            @click="deleteAvatar"
        >
          <span class="d-none d-sm-inline">{{ $t('Remove') }}</span>
          <feather-icon icon="TrashIcon" class="d-inline d-sm-none"/>
        </b-button>

        <b-modal id="bv-modal-example" hide-footer>

          <div class="d-block text-center">
            <cropper
                stencil-component="circle-stencil"
                ref="cropper"
                @change="onCrooperChange"
                v-if="img"
                class="cropper cropper_custom"
                :src="img"
                :default-position="defaultPosition"
                :default-size="defaultSize"
                :image-restriction="restrictionType"
                :resize-image="{
                  adjustStencil: true,
                     touch: true,
                        mouse: true,
                }"
                :stencil-props="{
                  movable: false,
                  resizable: true,
                  handlers: {},
                  lines: {},
                 }"
                :stencil-size="stencilSize"
            >
            </cropper>
          </div>
          <b-row>
            <b-col class="md-6">
              <div>
                <feather-icon @click="move('top')" icon="ArrowUpIcon" class="mt-1 zoom-button" size="22"/>
                <feather-icon @click="move('left')" icon="ArrowLeftIcon" class="mt-1 ml-1 zoom-button" size="22"/>
                <feather-icon @click="move('right')" icon="ArrowRightIcon" class="mt-1 ml-1 zoom-button" size="22"/>
                <feather-icon @click="move('bottom')" icon="ArrowDownIcon" class="mt-1 ml-1 zoom-button" size="22"/>
              </div>
            </b-col>
            <b-col class="md-6 zoom-group d-flex justify-content-end">
              <feather-icon @click="zoom(1.2)" icon="ZoomInIcon" class=" mt-1 zoom-button mb-2" size="22"/>
              <feather-icon @click="zoom(0.8)" icon="ZoomOutIcon" class="mt-1 ml-1 mb-2 zoom-button" size="22"/>
            </b-col>
          </b-row>
          <div>
            <span class="mt-1"
            >{{
                $t('You can zoom in and out by using your mouse wheel. Each scroll, up or down, increases or decreases the zoom factor. Please center your Event Picture')
              }}</span>
          </div>
          <div class="preview-result-example__previews">
            <preview class="preview-result-example__preview" :image="result.image" :coordinates="result.coordinates"/>
          </div>
          <b-button class="mt-3" block @click="uploadAvatar"> {{ $t('Save') }}</b-button>
        </b-modal>

      </div>
    </b-media>
    <b-row>
      <b-col md="6" xl="6" class="mb-1 mt-2">
        <b-form-group :label="$t('First Name')">
          <b-form-input
              v-model="currentItem.firstname"
              :placeholder="$t('First Name')"
          />
        </b-form-group>
      </b-col>

      <b-col md="6" xl="6" class="mb-1 mt-2">
        <b-form-group :label="$t('Name')">
          <b-form-input
              v-model="currentItem.name"
              :placeholder="$t('Name')"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6" xl="6" class="mb-1">
        <b-form-group :label="$t('Position')">
          <b-form-input
              v-model="currentItem.position"
              :placeholder="$t('Position')"
          />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="6" class="mb-1">
        <b-form-group :label="$t('Degree')">
          <b-form-input
              v-model="currentItem.degree"
              :placeholder="$t('Degree')"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6" xl="6" class="mb-1">
        <b-form-group :label="$t('Company')">
          <b-form-input
              v-model="currentItem.company"
              :placeholder="$t('Company')"
          />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="6" class="mb-1">
        <b-form-group :label="$t('University or Institute')">
          <b-form-input
              v-model="currentItem.institute"
              :placeholder="$t('University or Institute')"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-form-group :label="$t('City')">
      <b-form-input
          v-model="currentItem.city"
          :placeholder="$t('City')"
      />
    </b-form-group>
    <b-form-group :label="$t('Slogan/Motto')">
      <b-form-input
          v-model="currentItem.slogan"
          placeholder="Slogan/Motto"
      />
    </b-form-group>
  </div>
</template>

<script>
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import { BAvatar, BButton, BCol, BFormGroup, BFormInput, BMedia, BRow, } from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  name: 'PublicProfile',
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BMedia,
    BAvatar,
    Cropper,
    Preview
  },
  data() {
    return {
      img: null,
      imageName: '',
      restrictionType: 'none',
      stencilSize: {
        width: process.env.VUE_APP_AVATAR_WIDTH,
        height: process.env.VUE_APP_AVATAR_HEIGHT
      },
      result: {
        coordinates: null,
        image: null
      },
      size: {
        width: 100,
        height: 100,
      },
    }
  },

  computed: {
    imageCropActive() {
      if (this.$refs.refInputEl.files[0]) {

        return true
      }
    }
  },

  props: ['currentItem'],
  directives: {
    Ripple,
  },
  methods: {

    defaultPosition() {
      return {
        left: -50,
        top: -250,
      }
    },
    defaultSize() {
      return {
        width: process.env.VUE_APP_AVATAR_WIDTH,
        height: process.env.VUE_APP_AVATAR_HEIGHT,
      }
    },
    zoom(factor) {
      this.$refs.cropper.zoom(factor)
    },
    move(direction) {
      if (direction === 'left') {
        this.$refs.cropper.move(-this.size.width / 16)
      } else if (direction === 'right') {
        this.$refs.cropper.move(this.size.width / 16)
      } else if (direction === 'top') {
        this.$refs.cropper.move(0, -this.size.height / 16)
      } else if (direction === 'bottom') {
        this.$refs.cropper.move(0, this.size.height / 16)
      }
    },
    updateSize({ coordinates }) {
      this.size.width = Math.round(coordinates.width)
      this.size.height = Math.round(coordinates.height)
    },
    aspectRatioCustom() {
      return process.env.VUE_APP_AVATAR_RATIO
    },
    onCrooperChange({
      coordinates,
      image
    }) {
      this.result = {
        coordinates,
        image
      }
    },

    addAvatar(event) {
      if (event) event.preventDefault()
      const reader = new FileReader()
      let file = this.$refs.refInputEl.files[0]

      if (file) {
        reader.readAsDataURL(file)
        this.imageName = file.name
        reader.onload = (evt) => {
          let base64 = evt.target.result
          this.img = base64
          this.$bvModal.show('bv-modal-example')
        }
      }
    },

    uploadAvatar() {
      const { canvas } = this.$refs.cropper.getResult()
      const formData = new FormData()
      canvas.toBlob(async blob => {
        formData.append(
            'files',
            blob,
            this.imageName
        )

        this.$http
            .post(`/experts/profile/avatarupload`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              this.$emit('updateProfile')

              let userData = JSON.parse(localStorage.getItem('userData'))
              userData.profile.avatar.url = response.data.avatar.url
              localStorage.setItem('userData', JSON.stringify(userData))

              this.$bvModal.hide('bv-modal-example')
              this.$bvToast.toast(this.$i18n.t('Avatar uploaded successfully'), {
                title: this.$i18n.t('Success'),
                variant: 'success',
                toaster: 'b-toaster-bottom-right',
              })
            })
            .catch((e) => {
              this.$bvToast.toast(this.$i18n.t('Avatar not uploaded'), {
                title: this.$i18n.t('Error'),
                variant: 'danger',
                toaster: 'b-toaster-bottom-right',
              })
            })
      })
    },
    deleteAvatar() {
      this.$http
          .delete(`/experts/profile/avatarremove`)
          .then((response) => {
            this.currentItem.avatar.url = null

            let userData = JSON.parse(localStorage.getItem('userData'))
            userData.profile.avatar.url = null
            localStorage.setItem('userData', JSON.stringify(userData))

            this.$bvToast.toast(this.$i18n.t('Avatar deleted'), {
              title: this.$i18n.t('Success'),
              variant: 'success',
              toaster: 'b-toaster-bottom-right',
            })
          })
          .catch((e) => {
            this.$bvToast.toast(this.$i18n.t('Avatar not deleted'), {
              title: this.$i18n.t('Error'),
              variant: 'danger',
              toaster: 'b-toaster-bottom-right',
            })
          })
    },
    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },
  }
}
</script>

<style lang="scss">


/*.cropper_custom {*/
/*  position: fixed;*/
/*  top: 50%;*/
/*  left: 50%;*/
/*  transform: translate(-50%, -50%);*/
/*  height: 247px;*/
/*  z-index: 6;*/
/*}*/
.vue-advanced-cropper__background {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC);
}

.preview-result-example {
  display: flex;

  &__cropper {
    width: 300px;
  }

  &__previews {
    margin-left: 32px;
  }

  &__preview {
    border-radius: 50%;
    overflow: hidden;
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100px;
    height: 100px;
    border: 1px solid #c4c3c3;

    &--small {
      width: 60px;
      height: 60px;
    }
  }

  &__preview-image {
    width: 100%;
  }

  &__button {
    position: absolute;
    left: 16px;
    bottom: 0;
  }
}

.vue-rectangle-stencil {
  background: #ccc;
}

.vertical-buttons {
  position: initial;
  left: 2px;
  top: 80%;
  transform: translateY(-50%);
}

.square-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: background 0.5s;

  &:hover {
    background: #DDD;
  }
}

.zoom-button {
  display: inline;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  //margin-bottom: 5px;
  cursor: pointer;
  transition: background 0.5s;

  &:hover {
    background: #DDD;
  }
}
</style>
