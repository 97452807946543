<template>
<div>
  <b-row>
    <b-col md="6" xl="6" class="mb-1">
      <b-form-group :label="$t('Email')">
        <b-form-input
            v-model="currentItem.email"
            :placeholder="$t('Email')"
        />
      </b-form-group>
    </b-col>
    <b-col md="6" xl="6" class="mb-1">
      <b-form-group :label="$t('Phone')">
        <b-form-input
            v-model="currentItem.phone"
            :placeholder="$t('Phone')"
        />
      </b-form-group>
    </b-col>
  </b-row>
  <b-form-group :label="$t('Birthday')">
      <b-form-datepicker
          :name="$t('Birthday')"
          :placeholder="$t('Birthday')"
          show-decade-nav
          v-bind="labels[locale] || {}"
                         v-model="currentItem.birthday" />
  </b-form-group>

  <b-form-group :label="$t('About me')">
    <b-form-textarea
        md="12"
        rows="3"
        :placeholder="$t('About me')"
        v-model="currentItem.aboutme"
    />
  </b-form-group>
</div>
</template>

<script>
import {
  BCol,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BRow,

} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import moment from 'moment'
import Cleave from 'vue-cleave-component'
import {setDatePickerTranslation} from "@core/mixins/datePickerLocales";
export default {
  name: "EPersonalData",
  components: {
    BFormGroup,
    BFormDatepicker,
    Cleave,
    BRow,
    BCol,
    BInputGroupAppend,
    BFormTextarea,
    BInputGroup,
    BFormInput,
  }, directives: {
    Ripple,
  },
  mixins: [setDatePickerTranslation],
  props: ['currentItem'],
  methods: {
    onBirthdayDatePickerChange(ctx) {
      this.formatted = ctx.selectedFormatted
      this.selected = ctx.selectedYMD
      this.currentItem.birthday_text = moment(ctx.selectedYMD, 'YYYY-MM-DD')
          .format('DD.MM.YYYY')
    },
    onBirthdayInput() {
      let date = moment(this.currentItem.birthday_text, 'DD.MM.YYYY')

      if (date.isValid()) {
        this.currentItem.birthday = date.format('YYYY-MM-DD')
      } else {
        this.currentItem.birthday = null
        this.currentItem.birthday_text = ''
      }
    },
    getValidationState({
                         dirty,
                         validated,
                         valid = null
                       }) {
      return dirty || validated ? valid : null
    },

  }
}
</script>
