<template>
  <div>
    <div v-if="currentItem">
      <b-form-group
          class="w-100"
          :label="$t('Sections')"
          :description="$t('Please indicate the industries in which you are active or your target customers are')"
      >
        <v-select
            v-model="currentItem.sections"
            label="name"
            :options="sectionsCheckboxOptions"
            multiple
        >
        </v-select>
      </b-form-group>
    </div>
    <b-row class="mt-2">
      <b-col md="2">
        <div class="border rounded shadow pt-0 p-1 h-100">
          <h4 class="mb-2 mt-0"> {{ $t('Languages') }}</h4>
          <b-form-group>
            <b-form-checkbox
                v-for="option in languagesCheckboxOptions"
                :key="option.id"
                v-model="currentItem.languages"
                v-if="option.status"
                :value="option.id"
                name="clients"
                class="mb-1"
            >
              {{ option.name }}
            </b-form-checkbox>
          </b-form-group>
        </div>
      </b-col>
      <b-col md="6" class="pl-0" v-if="currentItem.expertTopics">
        <div class="border rounded shadow pt-0 p-1 h-100">
          <h4 class="mb-2 mt-0"> {{ $t('Experttopics') }}</h4>
        </div>
      </b-col>
      <b-col md="4" class="pl-0">
        <div class="border rounded shadow pt-0 p-1 h-100">
          <h4 class="mb-2 mt-0"> {{ $t('Targetgroups') }}</h4>
          <b-form-group>
            <b-form-checkbox
                v-for="option in targetgroupsCheckboxOptions"
                :key="option.id"
                v-model="currentItem.targetgroups"
                :value="option.id"
                v-if="option.status"
                name="targetgroups"
                class="mb-2"
            >
              {{ option.name }}
            </b-form-checkbox>
          </b-form-group>
        </div>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import { BCol, BFormCheckbox, BFormGroup, BFormInput, BFormSelect, BRow, BTab, } from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BTab,
    BCol,
    BRow,
    vSelect,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  name: 'Sections',
  props: ['currentItem'],

  data() {
    return {
      expertTopics: [],
      languagesCheckboxOptions: [],
      sectionsCheckboxOptions: [],
      targetgroupsCheckboxOptions: [],
      selected: null,
      options: [
        {
          value: null,
          text:  this.$i18n.t("Please select an option"),
        },
        {
          value: 'month',
          text:  this.$i18n.t("Months"),
        },
        {
          value: 'year',
          text:  this.$i18n.t("Years"),
        }
      ]
    }
  },
  methods: {
    getExpertTopics() {
      this.$http
          .get(`/experttopics`)
          .then((response) => {
            this.currentItem.expertTopics = response.data.items
            this.currentItem.expertTopics.forEach((item, index) => {
              item.published = false
              item.experience_years = ''
              if (this.currentItem.experttopics) {
                this.currentItem.experttopics.forEach(currentItem => {
                  if (currentItem.id == item.id) {
                    item.published = true
                    item.experience_years = currentItem.pivot.experience_years
                    item.period = currentItem.pivot.period
                  }
                })
              }
            })

            console.debug(this.currentItem.expertTopics)
          })
          .catch((error) => {
            console.log(error)
          })
    },
    getLanguages() {
      this.$http
          .get(`/languages`)
          .then((response) => {
            this.languagesCheckboxOptions = response.data.items
          })
          .catch((error) => {
          })
    },
    getTargetGroups() {
      this.$http
          .get(`/targetgroups`)
          .then((response) => {
            this.targetgroupsCheckboxOptions = response.data.items
          })
          .catch((error) => {
          })
    },
    getSections() {
      this.$http
          .get(`/sections`)
          .then((response) => {
            this.sectionsCheckboxOptions = response.data.items
          })
          .catch((error) => {
          })
    },
    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },
  },
  mounted() {
    console.debug(this.currentItem)
    this.getExpertTopics()
    this.getSections()
    this.getLanguages()
    this.getTargetGroups()
  },
}
</script>

<style scoped>

</style>
